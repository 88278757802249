// @flow
import * as React from 'react';

export type InitialFrames = {|
  device: string,
  playSegments: (frames: Array<[number, number]>, loop: boolean) => void,
|};

export default function playInitialFrames({
  device,
  playSegments,
}: InitialFrames) {
  if (playSegments) {
    return {
      mobile: () => {
        playSegments(
          [
            [0, 20],
            [85, 140],
            [195, 260],
            [260, 318],
            [140, 195],
            [85, 115],
          ],
          true,
        );
      },
      desktop: () => {
        playSegments([320, 350], true);
        setTimeout(() => {
          playSegments([85, 115], true);
        }, 2000);
      },
    }[device]();
  }
  return undefined;
}
